import Icon1 from "../../Assets/SVG/Sidebar/Icon-1.svg";

export const sideBarData = [
  {
    id: 0,
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.24994 0H1.74994C0.784973 0 0 0.784973 0 1.74994V6.24994C0 7.21509 0.784973 8.00006 1.74994 8.00006H9.24994C10.2151 8.00006 11.0001 7.21509 11.0001 6.24994V1.74994C11.0001 0.784973 10.2151 0 9.24994 0Z"
          fill="currentColor"
        />
        <path
          d="M9.24994 10H1.74994C0.784973 10 0 10.785 0 11.7501V22.2501C0 23.2151 0.784973 24.0001 1.74994 24.0001H9.24994C10.2151 24.0001 11.0001 23.2151 11.0001 22.2501V11.7501C11.0001 10.785 10.2151 10 9.24994 10Z"
          fill="currentColor"
        />
        <path
          d="M22.2501 16H14.7501C13.785 16 13 16.785 13 17.7501V22.2501C13 23.2151 13.785 24.0001 14.7501 24.0001H22.2501C23.2151 24.0001 24.0001 23.2151 24.0001 22.2501V17.7501C24.0001 16.785 23.2151 16 22.2501 16Z"
          fill="currentColor"
        />
        <path
          d="M22.2501 0H14.7501C13.785 0 13 0.784973 13 1.74994V12.2499C13 13.2151 13.785 14.0001 14.7501 14.0001H22.2501C23.2151 14.0001 24.0001 13.2151 24.0001 12.2499V1.74994C24.0001 0.784973 23.2151 0 22.2501 0V0Z"
          fill="currentColor"
        />
      </svg>
    ),
    path: "/dashboard",
  },
  {
    id: 1,
    icon: (
      <svg
        width="24"
        height="22"
        viewBox="0 0 24 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 8.68579C14.2523 8.68579 16.0781 6.85995 16.0781 4.60767C16.0781 2.35538 14.2523 0.529541 12 0.529541C9.74771 0.529541 7.92188 2.35538 7.92188 4.60767C7.92188 6.85995 9.74771 8.68579 12 8.68579Z"
          fill="currentColor"
        />
        <path
          d="M20.25 8.68568C21.6739 8.68568 22.8281 7.53141 22.8281 6.10755C22.8281 4.68369 21.6739 3.52942 20.25 3.52942C18.8261 3.52942 17.6719 4.68369 17.6719 6.10755C17.6719 7.53141 18.8261 8.68568 20.25 8.68568Z"
          fill="currentColor"
        />
        <path
          d="M3.75 8.68568C5.17386 8.68568 6.32812 7.53141 6.32812 6.10755C6.32812 4.68369 5.17386 3.52942 3.75 3.52942C2.32614 3.52942 1.17188 4.68369 1.17188 6.10755C1.17188 7.53141 2.32614 8.68568 3.75 8.68568Z"
          fill="currentColor"
        />
        <path
          d="M6.29016 11.0009C5.27531 10.1694 4.35623 10.2795 3.18281 10.2795C1.42781 10.2795 0 11.6989 0 13.4431V18.5623C0 19.3198 0.618281 19.9357 1.37859 19.9357C4.66106 19.9357 4.26562 19.9951 4.26562 19.7942C4.26562 16.1667 3.83597 13.5065 6.29016 11.0009Z"
          fill="currentColor"
        />
        <path
          d="M13.1161 10.2982C11.0665 10.1273 9.28506 10.3002 7.74845 11.5685C5.17703 13.6282 5.67189 16.4015 5.67189 19.7942C5.67189 20.6918 6.4022 21.4357 7.31345 21.4357C17.2079 21.4357 17.6017 21.7549 18.1885 20.4556C18.3809 20.0162 18.3281 20.1558 18.3281 15.9523C18.3281 12.6136 15.4372 10.2982 13.1161 10.2982Z"
          fill="currentColor"
        />
        <path
          d="M20.8172 10.2794C19.6373 10.2794 18.7233 10.1704 17.7098 11.0008C20.1457 13.4878 19.7344 15.9665 19.7344 19.7941C19.7344 19.9963 19.4061 19.9356 22.5722 19.9356C23.3597 19.9356 24 19.2976 24 18.5134V13.443C24 11.6987 22.5722 10.2794 20.8172 10.2794Z"
          fill="currentColor"
        />
      </svg>
    ),
    path: "/clients",
  },
  {
    id: 2,
    icon: (
      <svg
        width="20"
        height="24"
        viewBox="0 0 20 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 14.8771C4.47706 14.8771 0 16.4149 0 18.3128C0 19.3443 0.000588991 20.2695 1.60797 20.8995L1.94217 20.3071C2.5505 19.2278 3.76273 18.5578 5.10541 18.5578C6.19722 18.5578 7.20305 19.0013 7.86791 19.7476H12.1321C12.7972 19.0013 13.8031 18.5578 14.8949 18.5578C16.2376 18.5578 17.4492 19.2278 18.0581 20.3071L18.3923 20.8993C19.9997 20.2695 20 19.3443 20 18.3128C20 16.4154 15.5229 14.8771 10 14.8771Z"
          fill="currentColor"
        />
        <path
          d="M4.32129 8.46108C4.32188 11.2774 6.86884 13.5688 9.99999 13.5688C13.1314 13.5688 15.6781 11.2774 15.6781 8.46108C15.6781 8.30537 15.6693 8.15125 15.6537 7.99872H4.34573C4.33042 8.15125 4.32129 8.30537 4.32129 8.46108Z"
          fill="currentColor"
        />
        <path
          d="M4.02491 7.18137H15.9741C16.4403 7.18137 16.8174 6.84188 16.8174 6.42321C16.8174 6.00427 16.4403 5.66558 15.9741 5.66558H15.7451C15.7451 3.65141 14.463 1.90814 12.5939 1.05544L12.1178 3.48325C12.0798 3.67577 11.894 3.81082 11.6838 3.81082C11.6584 3.81082 11.6328 3.80871 11.6069 3.80473C11.3675 3.76634 11.2076 3.56111 11.2506 3.34555L11.7936 0.572423C11.5901 0.27901 11.2285 0.083313 10.8149 0.083313H9.1842C8.77139 0.083313 8.40981 0.27901 8.20605 0.572423L8.7496 3.34555C8.7923 3.56111 8.63241 3.76634 8.39273 3.80473C8.36682 3.80871 8.3412 3.81082 8.31588 3.81082C8.10594 3.81082 7.91926 3.67577 7.88216 3.48325L7.40574 1.05571C5.5363 1.90814 4.25458 3.65114 4.25458 5.66558H4.02491C3.55939 5.66558 3.1825 6.00454 3.1825 6.42321C3.1825 6.84188 3.55939 7.18137 4.02491 7.18137Z"
          fill="currentColor"
        />
        <path
          d="M15.1368 20.7693H17.0445C16.6461 20.0638 15.8346 19.5792 14.8945 19.5792C13.9549 19.5792 13.1425 20.0638 12.7444 20.7693H7.25476C6.85667 20.0638 6.04488 19.5792 5.105 19.5792C4.16484 19.5792 3.35275 20.0638 2.95496 20.7693H4.86268L5.105 21.1469L5.49073 21.7483L5.105 22.3492L4.86268 22.7268H2.95496C3.35275 23.4323 4.16484 23.9166 5.105 23.9166C6.04458 23.9166 6.85667 23.4323 7.25476 22.7265H12.7447C13.1425 23.4323 13.9549 23.9166 14.8945 23.9166C15.8343 23.9166 16.6461 23.4323 17.0445 22.7265H15.1368L14.8945 22.3489L14.5085 21.748L14.8945 21.1467L15.1368 20.7693Z"
          fill="currentColor"
        />
      </svg>
    ),
    path: "/constructors",
  },
  {
    id: 3,
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.9063 5.17367C12.8756 4.60114 12.2905 4.69854 11.7196 4.69854C12.1413 4.81086 12.5403 4.96713 12.9063 5.17367Z"
          fill="currentColor"
        />
        <path
          d="M6.8032 3.73877C4.58685 3.73877 3.5667 6.54408 5.33846 7.955C5.94245 6.34224 7.14482 5.18423 8.72106 4.72716C8.28988 4.13106 7.59324 3.73877 6.8032 3.73877Z"
          fill="currentColor"
        />
        <path
          d="M2.22452 7.51595C2.31485 7.80578 2.43187 8.08383 2.57089 8.34727L1.84285 9.07571C1.64734 9.27123 1.64734 9.58857 1.84285 9.78449L3.12613 11.0682C3.21959 11.1616 3.34764 11.2142 3.4811 11.2142C3.6131 11.2142 3.74029 11.1616 3.83536 11.0682L4.5626 10.3397C4.67173 10.3975 4.7927 10.4329 4.90573 10.4827C4.90573 10.4744 4.90492 10.4658 4.90492 10.4575C4.90492 9.86222 4.9733 9.30066 5.09503 8.77334C4.21698 8.20824 3.6311 7.22733 3.6311 6.1074C3.6311 4.358 5.05416 2.93453 6.80317 2.93453C7.98748 2.93453 9.01 3.59384 9.55583 4.5571C10.1386 4.48483 10.686 4.48483 11.3449 4.60934C11.2601 4.35294 11.1604 4.102 11.0355 3.86678L11.7635 3.13798C11.8585 3.04412 11.9111 2.91647 11.9111 2.78377C11.9111 2.65102 11.8585 2.52342 11.7635 2.42956L10.481 1.14668C10.3828 1.04852 10.2548 0.999821 10.1268 0.999821C9.99875 0.999821 9.87075 1.04852 9.77335 1.14668L9.04298 1.87549C8.77914 1.7357 8.5019 1.61868 8.21136 1.53033V0.500669C8.21136 0.224239 7.98829 0 7.71105 0H5.8961C5.62042 0 5.39497 0.224239 5.39497 0.500669V1.53028C5.10519 1.61863 4.8279 1.73565 4.56325 1.87544L3.83536 1.14744C3.73715 1.04928 3.60915 1.00058 3.4811 1.00058C3.3531 1.00058 3.22434 1.04928 3.12694 1.14704L1.84285 2.42991C1.74939 2.52418 1.6968 2.65137 1.6968 2.78453C1.6968 2.91764 1.74939 3.04488 1.84285 3.13874L2.57089 3.86714C2.43182 4.13103 2.31485 4.40867 2.22614 4.69845H1.19729C0.920052 4.69845 0.696167 4.92304 0.696167 5.19988V7.01488C0.696167 7.2909 0.920052 7.51595 1.19729 7.51595H2.22452Z"
          fill="currentColor"
        />
        <path
          d="M17.9467 17.2278C18.7494 16.7876 19.3007 15.945 19.3007 14.9671C19.3007 13.5401 18.1392 12.3794 16.7122 12.3794C16.2104 12.3794 15.7453 12.5294 15.348 12.7776C15.1101 13.7247 14.7354 14.6306 14.2454 15.4175C15.6346 15.6787 16.9093 16.3113 17.9467 17.2278Z"
          fill="currentColor"
        />
        <path
          d="M23.3029 13.9877C23.3029 13.6889 23.0602 13.4469 22.7626 13.4469H21.6521C21.5563 13.1339 21.4291 12.8339 21.2783 12.5493L22.0644 11.7632C22.1665 11.6618 22.223 11.5244 22.223 11.3811C22.223 11.2377 22.1665 11.1003 22.0644 10.9986L20.6805 9.61439C20.5754 9.50869 20.4363 9.45569 20.2982 9.45569C20.1599 9.45569 20.0217 9.50869 19.9165 9.61439L19.1288 10.4009C18.8444 10.2501 18.5452 10.1237 18.2311 10.0282V8.91775C18.2311 8.61932 17.9899 8.37738 17.6915 8.37738H15.7336C15.6024 8.37738 15.487 8.42998 15.3928 8.50776C15.5577 9.10932 15.648 9.7616 15.648 10.4574C15.648 10.8992 15.6135 11.3477 15.5491 11.7937C15.9135 11.6594 16.3014 11.575 16.7122 11.575C18.5829 11.575 20.1049 13.0967 20.1049 14.9669C20.1049 16.1623 19.4806 17.21 18.5436 17.8143C19.2254 18.562 19.7673 19.449 20.1199 20.4429C20.178 20.4629 20.2384 20.4775 20.2989 20.4775C20.4371 20.4775 20.5754 20.4249 20.6814 20.3192L22.066 18.935C22.1674 18.8336 22.2231 18.6962 22.2231 18.5529C22.2231 18.4096 22.1674 18.2722 22.066 18.1704L21.2783 17.3839C21.4291 17.0996 21.5563 16.7995 21.6522 16.4862H22.7626C22.9055 16.4862 23.0446 16.4297 23.1451 16.3275C23.2465 16.2266 23.3038 16.0888 23.3038 15.9455L23.3029 13.9877Z"
          fill="currentColor"
        />
        <path
          d="M19.0173 20.9323C18.1777 18.462 15.9591 16.7629 13.398 16.5316C12.5404 17.4398 11.4786 18.016 10.2761 18.016C9.07454 18.016 8.01191 17.4399 7.15511 16.5316C4.59249 16.7629 2.37382 18.462 1.53422 20.9327L1.07011 22.2989C0.937358 22.692 1.00093 23.1251 1.24287 23.4624C1.48476 23.7998 1.87432 24 2.28896 24H18.2625C18.6779 24 19.0675 23.7998 19.3094 23.4624C19.5505 23.1251 19.6149 22.692 19.4814 22.2989L19.0173 20.9323Z"
          fill="currentColor"
        />
        <path
          d="M6.11133 10.4575C6.11133 13.5216 7.97659 16.8095 10.2761 16.8095C12.5765 16.8095 14.4416 13.5216 14.4416 10.4575C14.4417 4.10584 6.11133 4.09325 6.11133 10.4575Z"
          fill="currentColor"
        />
      </svg>
    ),
    path: "/administration",
  },
  {
    id: 4,
    icon: (
      <svg
        width="20"
        height="24"
        viewBox="0 0 20 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 7V0.46C12.9251 0.809335 13.7653 1.35131 14.465 2.05L17.949 5.536C18.6485 6.23488 19.1909 7.07489 19.54 8H13C12.7348 8 12.4804 7.89464 12.2929 7.70711C12.1054 7.51957 12 7.26522 12 7ZM20 10.485V19C19.9984 20.3256 19.4711 21.5964 18.5338 22.5338C17.5964 23.4711 16.3256 23.9984 15 24H5C3.67441 23.9984 2.40356 23.4711 1.46622 22.5338C0.528882 21.5964 0.00158786 20.3256 0 19V5C0.00158786 3.67441 0.528882 2.40356 1.46622 1.46622C2.40356 0.528882 3.67441 0.00158786 5 0L9.515 0C9.678 0 9.839 0.013 10 0.024V7C10 7.79565 10.3161 8.55871 10.8787 9.12132C11.4413 9.68393 12.2044 10 13 10H19.976C19.987 10.161 20 10.322 20 10.485ZM12 19C12 18.7348 11.8946 18.4804 11.7071 18.2929C11.5196 18.1054 11.2652 18 11 18H6C5.73478 18 5.48043 18.1054 5.29289 18.2929C5.10536 18.4804 5 18.7348 5 19C5 19.2652 5.10536 19.5196 5.29289 19.7071C5.48043 19.8946 5.73478 20 6 20H11C11.2652 20 11.5196 19.8946 11.7071 19.7071C11.8946 19.5196 12 19.2652 12 19ZM15 15C15 14.7348 14.8946 14.4804 14.7071 14.2929C14.5196 14.1054 14.2652 14 14 14H6C5.73478 14 5.48043 14.1054 5.29289 14.2929C5.10536 14.4804 5 14.7348 5 15C5 15.2652 5.10536 15.5196 5.29289 15.7071C5.48043 15.8946 5.73478 16 6 16H14C14.2652 16 14.5196 15.8946 14.7071 15.7071C14.8946 15.5196 15 15.2652 15 15Z"
          fill="currentColor"
        />
      </svg>
    ),
    path: "/projects",
  },
  {
    id: 5,
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            d="M7.42395 21C7.81161 21.8914 8.45118 22.6502 9.2641 23.1831C10.077 23.716 11.0279 23.9999 12 23.9999C12.972 23.9999 13.9229 23.716 14.7358 23.1831C15.5487 22.6502 16.1883 21.8914 16.576 21H7.42395Z"
            fill="currentColor"
          />
          <path
            d="M22.3921 12.549L20.6561 6.82599C20.1001 4.82434 18.8914 3.06576 17.2219 1.82949C15.5524 0.59322 13.5177 -0.0498915 11.441 0.00226889C9.3642 0.0544293 7.36438 0.798871 5.75902 2.1174C4.15367 3.43592 3.03479 5.25295 2.58005 7.27999L1.23205 12.817C1.05289 13.5527 1.04318 14.3196 1.20366 15.0596C1.36415 15.7997 1.69063 16.4936 2.15847 17.0891C2.62632 17.6845 3.2233 18.1659 3.90437 18.497C4.58544 18.828 5.3328 19 6.09005 19H17.6071C18.3878 19 19.1577 18.8172 19.8551 18.4662C20.5525 18.1152 21.158 17.6058 21.6231 16.9787C22.0882 16.3517 22.4 15.6244 22.5335 14.8552C22.6671 14.0859 22.6186 13.2961 22.3921 12.549Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    path: "/notifications",
  },
];
