export const data = [
  {
    id: 0,
    name: "Kimberly Jones",
    projectName: "Project 1",
    status: "success",
    date: "21/04/2021",
  },
  {
    id: 1,
    name: "Kimberly Jones",
    projectName: "Project 1",
    status: "primary",
    date: "21/04/2021",
  },
  {
    id: 2,
    name: "Kimberly Jones",
    projectName: "Project 1",
    status: "warning",
    date: "21/04/2021",
  },
];
